import { Routes } from '@angular/router';
import { myCompanySectionGuard } from '@guards/my-company-section.can-activate-fn';
import { allCompaniesSectionGuard } from '@guards/all-companies-section.can-activate-fn';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { ERROR_PAGE_TEXT } from './pages/error-page/error-page-text.token';
import { unauthorizedSectionGuard } from '@guards/unauthorized-section.can-activate-fn';

export const CORE_ROUTES: Routes = [
  {
    // Business User has access only to him company
    path: '',
    canActivate: [myCompanySectionGuard],
    loadComponent: () =>
      import('./pages/company-section/my-company/my-company.component').then((r) => r.MyCompanyComponent),
    loadChildren: () => import('./pages/company-section/routes').then((r) => r.COMPANY_SECTION_ROUTES),
  },
  {
    // Internal User has access to all companies
    path: 'company/:company_system_name',
    canActivate: [allCompaniesSectionGuard],
    loadComponent: () =>
      import('./pages/company-section/company-page/company-page.component').then((r) => r.CompanyPageComponent),
    loadChildren: () => import('./pages/company-section/routes').then((r) => r.COMPANY_SECTION_ROUTES),
  },

  {
    path: 'companies',
    canActivate: [allCompaniesSectionGuard],
    loadComponent: () =>
      import('./pages/companies-list/companies-list.component').then((r) => r.CompaniesListComponent),
    title: 'companies.select_company',
  },

  {
    path: 'unauthorized',
    canActivate: [unauthorizedSectionGuard],
    component: ErrorPageComponent,
    providers: [{ provide: ERROR_PAGE_TEXT, useValue: 'Need to authorize' }],
  },
  { path: 'not-found', component: ErrorPageComponent },
  { path: '**', redirectTo: '/not-found' },
];
