import { Injectable, signal } from '@angular/core';
import { Company, CompanyBase } from '@type/company.type';

@Injectable({
  providedIn: 'root',
})
export class CurrentCompanyService {
  base = signal<CompanyBase | null>(null);
  systemName = signal<Company['system_name'] | null>(null);

  set(company: CompanyBase) {
    this.base.set(company);
    this.systemName.set(company.system_name);
  }

  reset() {
    this.base.set(null);
    this.systemName.set(null);
  }
}
