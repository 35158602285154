import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

function showErrorText() {
  const textEl = document.createElement('H1');
  textEl.classList.add('critical-error-message');
  textEl.innerText = "Oops, something went wrong. We're working on it.\nPlease refresh the page or try again later.";
  document.body.prepend(textEl);
}

bootstrapApplication(AppComponent, appConfig).catch((err) => {
  console.error(err);
  showErrorText();
});
