import { LfAccent } from '@leafio/ui/utils';

export const TOAST_TITLES: Record<LfAccent, string> = {
  brand: 'common.success',
  success: 'common.success',
  info: 'common.info',
  warning: 'common.warning',
  attention: 'common.attention',
  danger: 'common.error',
};
