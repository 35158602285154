import { computed, inject, Injectable } from '@angular/core';
import { UserType } from '@type/user.type';
import { EntitlementName, EntitlementPermissionLevel } from '@type/user-role.type';
import { ProfileService } from '@services/profile/profile.service';

export type AclEntitlementsMap = {
  [level in EntitlementPermissionLevel]?: {
    [entitlementName in EntitlementName]?: boolean;
  };
};

@Injectable({
  providedIn: 'root',
})
export class AclService {
  protected profileService = inject(ProfileService);
  protected userType = computed(() => this.profileService.currentUser()?.type);
  protected userEntitlements = computed(() => this.profileService.currentUser()?.role?.role_entitlements || []);

  isUserSet = computed(() => !!this.profileService.currentUser());

  isUserInternal = computed(() => this.userType() === 'ApplicationUser');
  isUserBusiness = computed(() => this.userType() === 'BusinessUser');

  entitlementsMap = computed<AclEntitlementsMap>(() => {
    const result: Record<string, Record<string, boolean>> = {};

    this.userEntitlements().forEach((entitlement) => {
      const level = entitlement.active_permission?.name || 'denied';
      result[level] = result[level] || {};
      result[level][entitlement.name] = true;
    });

    return result as AclEntitlementsMap;
  });

  isUserType(type: UserType) {
    return this.userType() === type;
  }

  protected isEntitlement(name: EntitlementName, level: EntitlementPermissionLevel): boolean {
    return !!this.entitlementsMap()[level]?.[name];
  }

  isAllowedFull(name: EntitlementName): boolean {
    return this.isEntitlement(name, 'full_access');
  }

  isAllowedView(name: EntitlementName): boolean {
    return this.isEntitlement(name, 'view');
  }

  isAllowedViewOrFull(name: EntitlementName): boolean {
    return this.isEntitlement(name, 'view') || this.isEntitlement(name, 'full_access');
  }

  isDenied(name: EntitlementName): boolean {
    return this.isEntitlement(name, 'denied');
  }

  isSomeAllowedFull(names: EntitlementName[]): boolean {
    return names.some((name) => this.isEntitlement(name, 'full_access'));
  }
}
