import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { API_URL } from '@tokens/api-url.token';
import { joinUrls } from '@utils/urls';
import { Observable } from 'rxjs';
import { ASSETS_URL } from '@tokens/assets-url.token';
import { API_ENDPOINTS } from '@tokens/api-endpoints.token';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  private apiUrl = inject(API_URL);
  private assetsUrl = inject(ASSETS_URL);
  private endpoints = inject(API_ENDPOINTS);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { url } = req.clone();

    if (url.includes(this.assetsUrl)) return next.handle(req);

    const isRequestToApi = Object.values(this.endpoints).some((value) => url.includes(value));
    if (isRequestToApi) {
      return next.handle(req.clone({ url: joinUrls(this.apiUrl, url) }));
    }

    return next.handle(req);
  }
}
