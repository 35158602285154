import { Component, effect, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { LfMainMenuComponent } from '@leafio/ui/layout';
import { TranslateService } from '@ngx-translate/core';
import { LfToastsOutlet } from '@leafio/ui/toasts';
import { MenuService } from '@services/menu/menu.service';
import { ProfileService } from '@services/profile/profile.service';
import { DEFAULT_APP_LOCALE } from '@tokens/default-app-locale.const';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, LfMainMenuComponent, LfToastsOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  profileService = inject(ProfileService);
  translate = inject(TranslateService);
  menuService = inject(MenuService);

  constructor() {
    effect(() => {
      this.translate.setDefaultLang(DEFAULT_APP_LOCALE);
      this.translate.use(this.profileService.locale());
    });
  }
}
