import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { BEARER_TOKEN, TOKEN } from '@services/auth/param-names';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private defaultView = inject(DOCUMENT).defaultView;

  setToken(token: string): void {
    localStorage.setItem(BEARER_TOKEN, token);
  }

  getToken(): string | null {
    return localStorage.getItem(BEARER_TOKEN);
  }

  clear() {
    [BEARER_TOKEN].forEach((t) => localStorage.removeItem(t));
  }

  init() {
    let needToReload = false;

    needToReload = this.setTokenFromUrl() || needToReload;

    if (needToReload) {
      this.reloadWithoutParams();
    }
  }

  private setTokenFromUrl(): boolean {
    if (!this.getToken() && this.defaultView) {
      const token = new URLSearchParams(this.defaultView.location.search).get(TOKEN);
      if (token) {
        this.setToken(token);
        return true;
      }
    }
    return false;
  }

  private reloadWithoutParams() {
    this.defaultView?.history.replaceState({}, this.defaultView.document.title, this.defaultView.location.pathname);
  }
}
