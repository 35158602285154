import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  protected translateService = inject(TranslateService);
  protected title = inject(Title);

  set(value: string | undefined, interpolateOptions: Record<string, unknown> = {}) {
    if (value) {
      this.translateService.get(value, interpolateOptions).subscribe((translatedTitle) => {
        this.title.setTitle(translatedTitle + ' - Leafio Super App');
      });
    } else {
      this.title.setTitle('Leafio Super App Admin Panel');
    }
  }
}
