import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AclService } from '@acl/service/acl.service';

export const allCompaniesSectionGuard: CanActivateFn = () => {
  const acl = inject(AclService);
  const router = inject(Router);

  if (!acl.isUserSet()) {
    return router.createUrlTree(['/unauthorized']);
  }

  return acl.isUserInternal() || router.createUrlTree(['/']);
};
