import { inject, Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TitleService } from '@services/title/title.service';

@Injectable()
export class TranslatePageTitleStrategy extends TitleStrategy {
  private titleService = inject(TitleService);

  override updateTitle(snapshot: RouterStateSnapshot): void {
    this.titleService.set(this.buildTitle(snapshot));
  }
}
