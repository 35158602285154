import { effect, inject, Injectable } from '@angular/core';
import { LfPaginatorIntlService } from '@leafio/ui/paginator';
import { ProfileService } from '@services/profile/profile.service';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class PaginationIntlService extends LfPaginatorIntlService {
  protected profileService = inject(ProfileService);
  protected translateService = inject(TranslateService);

  protected rowsKey = 'common.pagination.rows';
  protected ofKey = 'common.pagination.of';

  override rows = this.translateService.instant(this.rowsKey);
  override of = this.translateService.instant(this.ofKey);

  localeEffect = effect(async () => {
    this.profileService.locale(); // eval signal to make this effect dependent from locale
    this.rows = await firstValueFrom(this.translateService.get(this.rowsKey));
    this.of = await firstValueFrom(this.translateService.get(this.ofKey));
  });
}
