import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AclService } from '@acl/service/acl.service';

export const myCompanySectionGuard: CanActivateFn = () => {
  const acl = inject(AclService);
  const router = inject(Router);

  if (!acl.isUserSet()) {
    return router.createUrlTree(['/unauthorized']);
  }

  return acl.isUserBusiness() || router.createUrlTree(['/companies']);
};
