export const environment = {
  production: false,
  local: false,
  urls: {
    api: 'https://api-dev.super-app.leafio.cloud/',
    login: 'https://auth-dev.super-app.leafio.cloud/',
    assets: 'assets/',
    s3Bucket: 'https://leafio-superapp.s3.eu-central-1.amazonaws.com',
  },
  endpoints: {
    user_reset_password: 'api/users/reset_password',
    user_invitation: 'api/users/invitation',
    current_user: 'api/v1/users/current_user',

    company: 'admin/v1/company',
    company_timezones: 'admin/v1/company/timezones',
    company_settings: 'admin/v1/company/settings',
    company_start_exchange: 'admin/v1/company/start_platform_exchange',
    companies_available_for_user: 'admin/v1/company/available_for_user',

    roles: 'admin/v1/roles',

    users: 'admin/v1/users',
    users_all: 'admin/v1/users/list',
    users_upload: 'admin/v1/users/upload',
    related_users: 'admin/v1/user_stores/related_users',

    stores: 'admin/v1/stores',
    stores_all: 'admin/v1/stores/list',
    related_stores: 'admin/v1/user_stores/related_stores',

    assigned_users: 'admin/v1/user_stores/assigned/user',
    assigned_stores: 'admin/v1/user_stores/assigned/store',
    assign_users: 'admin/v1/user_stores/assign/user',
    assign_stores: 'admin/v1/user_stores/assign/store',

    tasks: 'admin/v1/issues',
    task_statuses: 'admin/v1/issue_statuses',

    direct_upload: 'rails/active_storage/direct_uploads',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDHl_74UsI4Rv_zSNnFghQi9xFOG6XhxMM',
    authDomain: 'superapp-8d649.firebaseapp.com',
    projectId: 'superapp-8d649',
    storageBucket: 'superapp-8d649.appspot.com',
    messagingSenderId: '466780613486',
    appId: '1:466780613486:web:ad9625c5cae7ad9da6ef7f',
    measurementId: 'G-Y5J9CT1REL',
  },
};
