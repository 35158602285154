import { computed, inject, Injectable, signal } from '@angular/core';
import { AppLocale } from '@type/app-locale';
import { catchError, firstValueFrom, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINTS } from '@tokens/api-endpoints.token';
import { User } from '@type/user.type';
import { DEFAULT_APP_LOCALE } from '@tokens/default-app-locale.const';
import { LfToastService } from '@leafio/ui/toasts';
import { APP_LOCALES } from '@constants/app-locales';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  protected http = inject(HttpClient);
  protected endpoints = inject(API_ENDPOINTS);
  protected toastService = inject(LfToastService);

  currentUser = signal<User | null>(null);
  locale = signal<AppLocale>(DEFAULT_APP_LOCALE);
  localeFullCode = computed(() => {
    const code = this.locale();
    return APP_LOCALES.find((locale) => locale.code === code)?.fullCode;
  });

  initialize() {
    return this.loadCurrentUser().pipe(
      tap((user) => {
        this.currentUser.set(user);
        this.locale.set(user.localization || DEFAULT_APP_LOCALE);
      }),
      catchError(() => of()),
    );
  }

  protected loadCurrentUser() {
    return this.http.get<User>(this.endpoints.current_user);
  }

  protected saveLocale(value: AppLocale) {
    return this.http
      .put<User>(this.endpoints.current_user, {
        user: {
          localization: value,
        },
      })
      .pipe(tap((user) => this.currentUser.set(user)));
  }

  async useLocale(value: AppLocale) {
    if (value === this.locale()) {
      return;
    }
    this.locale.set(value);

    try {
      await firstValueFrom(this.saveLocale(value));
    } catch (e) {
      this.toastService.danger('error.save_data');
    }
  }
}
