import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { LfPlaceholder } from '@leafio/ui/placeholder';
import { ERROR_PAGE_TEXT } from './error-page-text.token';

@Component({
  selector: 'app-error-page',
  standalone: true,
  imports: [LfPlaceholder],
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPageComponent {
  text = inject(ERROR_PAGE_TEXT);
}
