import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';
import { Observable, catchError, throwError } from 'rxjs';
import { API_URL } from '@tokens/api-url.token';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private auth = inject(AuthService);
  private api_url = inject(API_URL);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const cloned = req.clone();

    if (cloned.url.startsWith(this.api_url)) {
      return next.handle(req).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === HttpStatusCode.Unauthorized) {
              this.auth.toLoginPage();
            }
          }
          return throwError(() => error);
        }),
      );
    }
    return next.handle(req);
  }
}
