import { inject, Injectable, Injector, signal } from '@angular/core';
import { logEvent, Analytics, isSupported } from '@angular/fire/analytics';
import { environment } from '@environments/environment';

export type AnalyticsCallOptions = {
  global: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  protected analytics?: Analytics;
  protected injector = inject(Injector);

  enabled = signal(false);

  async init() {
    if (!environment.local && (await isSupported())) {
      this.analytics = this.injector.get(Analytics);
      this.enabled.set(true);
    }
  }

  logEvent(
    name: string,
    eventParams?: { [p: string]: unknown } | undefined,
    options?: AnalyticsCallOptions | undefined,
  ) {
    if (!this.enabled() || !this.analytics) {
      return;
    }
    logEvent(this.analytics, name, eventParams, options);
  }

  static getStrLengthForEvent(value: string | null | undefined): string {
    const length = (value || '').length;
    if (length < 1) {
      return '0';
    }
    if (length <= 10) {
      return '0-10';
    }
    if (length <= 20) {
      return '11-20';
    }
    if (length <= 50) {
      return '21-50';
    }
    if (length <= 100) {
      return '51-100';
    }
    if (length <= 200) {
      return '101-200';
    }
    if (length <= 500) {
      return '201-500';
    }
    return '500+';
  }
}
