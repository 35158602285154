export const joinUrls = (...urls: (string | number)[]): string => {
  let url = '';
  urls.forEach((u) => {
    u = u.toString();
    if (u.startsWith('/')) {
      url = url.concat(u);
    } else if (u.startsWith('http')) {
      url = url.concat(u);
    } else {
      url = url.concat(url.length && !url.endsWith('/') ? '/' + u : u);
    }
  });

  return url;
};
